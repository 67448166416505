export const data = { 
    intro_text: "hello hello",
    tldr_text: "I recently graduated from Brown University (December 2022) with Sc.B Honors in Computer Science. My work so far has been centered around questions in sociology, psychology, and economics, and I have taken a computational, data-driven, machine learning approach to them. I am currently looking for full-time opportunities that fit my profile and interests, as well as working on ongoing research projects.",
    links: { 
        twitter: '',
        email: 'mailto:aryan_srivastava@alumni.brown.edu',
        github: 'https://github.com/aryan096',
        linkedin: 'https://www.linkedin.com/in/aryan096/',
        instagram: 'https://www.instagram.com/ary.sriv/',
        facebook: 'https://www.facebook.com/aryansrivastava096/',
        behance: '',
    }
}