export const photos = [
    {
      src: '/photos/cvs.jpg',
      width: 4,
      height: 3
    },
    {
      src: '/photos/110street.jpg',
      width: 1,
      height: 1
    },
    {
      src: '/photos/click.jpg',
      width: 5,
      height: 3
    },
    {
      src: '/photos/governer.jpg',
      width: 3,
      height: 3
    },
    {
      src: '/photos/leh_moon.jpg',
      width: 4,
      height: 3
    },
    {
      src: '/photos/meteors.jpg',
      width: 4,
      height: 3
    },
    {
      src: '/photos/night_city.jpg',
      width: 4,
      height: 3
    },
    {
      src: '/photos/rain_run.jpg',
      width: 4,
      height: 3
    },
    {
      src: '/photos/mueza.jpeg',
      width: 4,
      height: 3
    },
    {
      src: '/photos/alien.jpeg',
      width: 4,
      height: 3
    },
    {
      src: '/photos/bnw.jpeg',
      width: 4,
      height: 3
    },
    {
      src: '/photos/blueorb.jpeg',
      width: 4,
      height: 3
    },
  ];
  